import { Fragment } from 'react';
import classnames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { Icon } from '@webfx/core-web';

import styles from './Dropdown.module.css';

const defaultClassNames = {
  dropdownButton: '',
  dropdownButtonText: '',
  listBoxOptions: '',
  listBoxOption: '',
  listBoxOptionActive: '',
};

/**
 * Copied with minimal adjustments from packages/web/marketingcloud-web/src/ui/pages/Prospector/components/Dropdown/Dropdown.jsx
 * @param {object} props
 * @param {string} props.iconLeft
 * @param {string|object} props.selected
 * @param {Function} props.onChange
 * @param {object} props.options
 * @param {string} props.buttonText
 * @param {string} props.iconRight
 * @param {object} props.classNames
 */
export default function Dropdown({
  iconLeft,
  selected,
  onChange,
  options,
  buttonText,
  iconRight,
  classNames: customClassNames = defaultClassNames,
}) {
  return (
    <Listbox value={selected} onChange={onChange}>
      <div className={classnames(customClassNames.dropdownContainer, 'position-relative')}>
        <Listbox.Button
          className={classnames(styles.dropdownButton, customClassNames.dropdownButton)}
          data-fx-name="dropdownButton"
        >
          {iconLeft ? <Icon type={iconLeft} /> : null}
          <span
            className={classnames(styles.dropdownButtonText, customClassNames.dropdownButtonText)}
            data-fx-name="dropdownValue"
          >
            {buttonText || options.find((option) => option.value === selected)?.label}
          </span>
          {iconRight ? <Icon type={iconRight} /> : null}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave={styles.transitionLeave}
          leaveFrom={styles.transitionLeaveFrom}
          leaveTo={styles.transitionLeaveTo}
        >
          <Listbox.Options
            className={classnames(styles.listBoxOptions, customClassNames.listBoxOptions)}
            data-fx-name="listBoxOptions"
          >
            {options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active, selected }) =>
                  classnames(
                    styles.listBoxOption,
                    active
                      ? `bg-gray-100 ${customClassNames.listBoxOptionActive}`
                      : 'text-gray-700',
                    selected ? 'font-semibold bg-gray-300' : 'font-weight-normal',
                    customClassNames.listBoxOption
                  )
                }
                value={option.value}
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
