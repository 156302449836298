import React from 'react';
import { Link } from 'react-router-dom';
import { useResize } from '@webfx/web-context';
import { useRouter, useApp, useLayout } from '@webfx/web-hooks';
import classnames from 'classnames';
import { useStoreActions } from 'easy-peasy';

import styles from './DropdownItem.module.css';

const DropdownItem = React.forwardRef(
  ({ href = '', children, data, index, style, onClick, ...passThroughProps }, ref) => {
    const { url, active, params } = useRouter();
    const [app] = useApp();

    const setActiveSiteId = useStoreActions((actions) => actions.sites.setActive);
    const { retainParams } = useLayout();
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const { setSize } = useResize();

    React.useEffect(() => {
      if (resolvedRef.current) {
        setSize({
          id: index,
          size: resolvedRef.current.getBoundingClientRect().height,
        });
      }
    }, [index, resolvedRef, setSize]);

    let routeName = active;
    if (app === 'cgfx') {
      routeName = 'cgfxKanban';
    } else if (active === 'crmContact') {
      routeName = 'crmContacts';
    }

    // Update store state immediately to avoid race conditions
    const handleClick = () => {
      setActiveSiteId(data.siteId);
      sessionStorage.removeItem('retryReload');
    };

    return (
      <div ref={resolvedRef} style={style} onClick={onClick}>
        <Link
          to={
            href ||
            url({
              route: routeName,
              ...(retainParams ? params : {}),
              siteId: data.siteId,
            })
          }
          role="button"
          {...passThroughProps}
          key={`site-${data.siteId}`}
          data-fx-name="menuItems"
          className={classnames('d-block py-2 px-3 border-bottom', styles.link)}
          onClick={handleClick}
        >
          <h4 className={styles.name}>{children}</h4>
          <p className={styles.url}>{data.url.replace(/^http(s)?:\/\/(www\.)?/, '')} </p>
        </Link>
      </div>
    );
  }
);

export default DropdownItem;
