import { useCallback, useEffect, useState } from 'react';

import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';
import useAuth from './useAuth';

/**
 * @typedef {object} NutshellCreateInstance
 * @property {Function} create - The create function creates a Nutshell instance using active site
 * @property {boolean} isProvisioning - isProvisioning is set to true when a Nutshell instance is being created and set to false when the process is done
 * @property {boolean} isProvisioned - isProvisioned is set to true when an active site already has a Nutshell instance.
 * @property {object} data - Data is an object with keys nsInstanceId (Nutshell instance id)and nsActive (Nutshell site active status )
 */

/**
 * This hook returns the below object
 * @param {*} params
 * @returns {NutshellCreateInstance}
 */
const useCreateNutshellInstance = (params) => {
  const site = useActiveSite({ $cache: false });
  const { user } = useAuth();

  const [isProvisioning, setIsProvisioning] = useState(false);
  const [isProvisioned, setProvisioned] = useState(false);
  const [isErrored, setErrored] = useState(false);
  const [data, setData] = useState();

  const nutshellMutator = api.useMutation(['nutshell'], params);
  const queryClient = api.useQueryClient();

  api.useQuery([`nutshell`, site.siteId], {
    enabled: isProvisioning && !isProvisioned,
    refetchInterval: 2000,
    onSuccess: async (data) => {
      if (data.nsInstanceId) {
        setData({
          nsInstanceId: data.nsInstanceId,
          nsActive: true,
        });

        const siteId = site.siteId;

        queryClient.setQueryData([`users/${user.userId}/nutshell`, { siteId }], {
          nsAuthToken: data.auth,
        });

        await Promise.all([
          queryClient.invalidateQueries(`users/${user.userId}/nutshell`),
          queryClient.invalidateQueries('sites'),
          queryClient.invalidateQueries('nutshell/auth'),
        ]);

        setProvisioned(true);
        setIsProvisioning(false);
      }
    },
  });

  useEffect(() => {
    if (site.nsInstanceId) {
      setProvisioned(true);
      setIsProvisioning(false);
      setData({
        nsInstanceId: site.nsInstanceId,
        nsActive: site.nsActive,
      });
    }
  }, [site.nsInstanceId]);

  const create = useCallback(
    async (_throwOnError) => {
      if (isProvisioned) {
        throw new Error('Nutshell instance has already been created');
      }

      await nutshellMutator.mutateAsync(
        {
          _method: 'create',
          siteId: site.siteId,
        },
        {
          onSuccess: () => {
            setIsProvisioning(true);
          },
          onError: (error) => {
            setData({
              error,
            });
            setErrored(true);
            if (_throwOnError) {
              throw error;
            }
            setIsProvisioning(false);
          },
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps -- old code
    },
    [isProvisioned, site.siteId]
  );

  return {
    create,
    isProvisioning,
    isProvisioned,
    isErrored,
    isLoading: site.isLoading,
    isCreating: nutshellMutator.isLoading,
    data,
  };
};

export default useCreateNutshellInstance;
